<template>
  <div class="personal">
    <div style="background-color: #fff">
      <el-row>
        <el-col :span="4">
          <el-menu default-active="3-1" @select="check" class="el-menu-vertical-demo" @click="check">
            <el-menu-item index="1">
              <span slot="title">账号管理</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">套餐购买</span>
            </el-menu-item>
            <el-submenu index="3">
              <template slot="title">
                <span>我的简历</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="3-1" style="width:100%;">简历管理</el-menu-item>
                <el-menu-item index="3-2">投递记录</el-menu-item>
                <el-menu-item index="3-3">收藏企业</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="4">
              <span slot="title">中介担保</span>
            </el-menu-item>
            <el-menu-item index="5">
              <span slot="title">培训订单</span>
            </el-menu-item>
            <el-menu-item index="6">
              <span slot="title">证件办理</span>
            </el-menu-item>
            <el-menu-item index="7">
              <span slot="title">我的订阅</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="20">
          <div class="menuContent">
            <div class="resumeManage">
              <div v-if="resumeList.length == 0" class="noResume">
                <div>您还没有简历，请先发布简历</div>
                <el-button type="primary" @click.stop="addResume">新增简历</el-button>
              </div>
              <div v-else>
                <div class="bottomBtn">
                  <el-button plain type="primary" @click="amend">
                    修改简历
                  </el-button>
                  <el-button plain type="primary" @click.stop="refreshResume">
                    刷新简历
                  </el-button>
                  <el-button plain type="primary" @click="stick">
                    置顶简历
                  </el-button>
                  <el-button plain type="primary" @click="openResume">
                    {{
                      resumeList[tabIndex].isOpen ? "隐藏简历" : "公开简历"
                    }}
                  </el-button>
                  <!-- <el-button plain type="primary" @click="delResume">
                    删除简历
                  </el-button> -->
                  <el-button type="primary" @click="recruit" style="background-color: #2089ff">
                    投递简历
                  </el-button>
                </div>
                <el-button style="z-index: 99;max-width: 166px;" class="addBtn" type="primary" @click.stop="addResume">
                  新增简历
                </el-button>
                <el-button style="z-index: 99;max-width: 166px;color:red" class="addBtn1" @click="allRefresh">一键刷新简历</el-button>
                <el-button style="z-index: 99;max-width: 166px;right:216px" class="addBtn1" @click="delResume">删除简历</el-button>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane v-for="(item, index) in resumeList" :key="index" :label="'简历' + (index + 1)"
                    :name="item.id + ''">

                    <div class="tableContent">
                      <div>
                        <div class="borderLeft">基本情况</div>
                        <el-form class="form_border" label-position="left" label-width="5.5vw" :model="item">
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="您的称谓："><el-input v-model="item.userName"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="您的性别：">
                                <el-input v-model="item.sex" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="出生日期："><el-input v-model="item.birthday"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="婚姻状况："><el-input v-model="item.marriage"
                                  readonly></el-input></el-form-item>
                              <!-- <el-form-item label="籍贯："
                                ><el-input
                                  v-model="item.nativeCity"
                                  readonly
                                ></el-input
                              ></el-form-item> -->
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="民族："><el-input v-model="item.nation"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="身高："><el-input v-model="item.height"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="毕业时间："><el-input v-model="item.GraduationDate"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="毕业院校："><el-input v-model="item.school"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="外语水平："><el-input v-model="item.englishSkill"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                          <!-- <el-row>
                            <el-col :span="8">
                            </el-col>
                            <el-col :span="8"> -->
                          <!-- <el-form-item label="所学专业："
                                ><el-input
                                  v-model="item.major"
                                  readonly
                                ></el-input
                              ></el-form-item> -->
                          <!-- </el-col>
                          </el-row> -->
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="证书等级："><el-input v-model="item.certificate"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="现居住地："><el-input v-model="item.currentResidence"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="现任职务："><el-input v-model="item.nowJob"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="航海经验："><el-input v-model="item.sailingYear"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="特殊技能："><el-input v-model="item.specialSkill"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                        </el-form>
                      </div>
                      <div class="items">
                        <div class="borderLeft">应聘期望</div>
                        <el-form class="form_border" label-position="left" label-width="5.5vw" :model="item">
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="应聘职务："><el-input v-model="item.expectJob"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="月薪要求："><el-input v-model="item.salary"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="航线要求："><el-input v-model="item.sailLine"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8">
                              <el-form-item label="吨位要求："><el-input v-model="item.tonnage"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="船型要求："><el-input v-model="item.shipType"
                                  readonly></el-input></el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="上船地点："><el-input v-model="item.contractPeriod"
                                  readonly></el-input></el-form-item>
                            </el-col>
                          </el-row>
                        </el-form>
                      </div>
                      <div class="items">
                        <div class="borderLeft">
                          <span>航海经历</span>
                          <span class="tips">船员管家温馨提示：请详细填写您的航海经历有助于招聘企业对您的了解与信任,增加就业机会,请尽可能详细填写。</span>
                        </div>
                        <el-table :data="item.experiences" border :header-cell-style="{
                          'background-color': 'rgba(32, 137, 255, 0.07)',
                          color: '#333',
                          'font-size': '0.833vw',
                          'font-weight': '400',
                        }" style="width: 100%">
                          <el-table-column prop="startEnd" label="起止时间" min-width="150"></el-table-column>
                          <el-table-column prop="company" label="服务公司"></el-table-column>
                          <el-table-column prop="job" label="担任职务"></el-table-column>
                          <el-table-column prop="shipName" label="船舶名称"></el-table-column>
                          <el-table-column prop="shipType" label="船舶类型"></el-table-column>
                          <el-table-column prop="sailLine" label="船舶航线"></el-table-column>
                          <el-table-column prop="tonnage" label="吨位/马力"></el-table-column>
                        </el-table>
                      </div>
                      <div class="items">
                        <div class="borderLeft">持有证书</div>
                        <el-table :data="item.wantedCertificates" border :header-cell-style="{
                          'background-color': 'rgba(32, 137, 255, 0.07)',
                          color: '#333',
                          'font-size': '0.833vw',
                          'font-weight': '400',
                        }" style="width: 100%">
                          <el-table-column prop="name" label="证书名称"></el-table-column>
                          <!-- <el-table-column prop="detailId" label="持有情况"></el-table-column> -->
                          <el-table-column prop="number" label="证书编号"></el-table-column>
                          <el-table-column prop="startDate,endDate" label="持有情况">
                            <template slot-scope="scope">
                              {{ scope.row.status }}
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="items">
                        <div class="borderLeft">求职备注</div>
                        <el-form class="form_border" label-position="left" label-width="5.5vw" :model="item">
                          <el-form-item label="备注说明:"><el-input type="textarea" :autosize="{ minRows: 5 }" resize="none"
                              v-model="item.memo" readonly></el-input></el-form-item>
                        </el-form>
                      </div>
                      <div class="items">
                        <div class="borderLeft">联系方式</div>
                        <div class="contactInfo">
                          <el-form class="form_border" label-position="left" label-width="5.5vw" :model="item">
                            <el-row>
                              <el-col :span="8">
                                <el-form-item label="手机号码："><el-input v-model="item.phone"
                                    readonly></el-input></el-form-item>
                              </el-col>
                              <el-col :span="8">
                                <el-form-item label="微信："><el-input v-model="item.wx" readonly></el-input></el-form-item>
                              </el-col>
                              <el-col :span="8">
                                <el-form-item label="QQ："><el-input v-model="item.qq" readonly></el-input></el-form-item>
                              </el-col>
                            </el-row>
                            <!-- <el-col :span="8">
										<el-form-item label="邮箱地址："><el-input v-model="item.email" readonly></el-input></el-form-item>
									</el-col> -->
                          </el-form>
                        </div>
                      </div>
                      <div class="items">
                        <div class="contactInfo">
                          <el-form label-position="left" label-width="5.5vw" :model="item" style="display: flex">
                            <el-form-item label="是否公开：">
                              <el-switch v-model="item.isOpen" disabled>
                              </el-switch>
                            </el-form-item>

                            <el-form-item style="margin-left: 50px" label="自动求职：">
                              <el-switch v-model="item.autoApply" disabled>
                              </el-switch>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                    </div>

                  </el-tab-pane>
                </el-tabs>
                <el-dialog custom-class="packageUse" title="请选择要使用的置顶套餐" :visible.sync="dialogVisible" width="30%"
                  :before-close="handleClose">
                  <div class="takeEffect">
                    <div :data-id="item.id" ref="package" style="display: inline-block" @click="checkPackage(idx)"
                      class="info" v-for="(item, idx) in packetList" :class="{ 'check': item.isCheck }" :key="idx">
                      <div class="content" style="width: 150px; margin-bottom: 0;background:none">
                        <div class="bold">{{ item.name }}</div>
                        <div class="enterprise">
                          <div>
                            <div>时长（{{ unit }}）：{{ item.timeLong }}</div>
                            <div>次数（次）：{{ item.num }}</div>
                            <div style="margin-top: 20px">金额：<span style="color: #f95022">￥{{ item.free }}</span></view>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="buyConfirm">确 定</el-button>
                  </span>
                </el-dialog>
              </div>
            </div>
          </div>
          <el-dialog title="提示" :visible.sync="showSub" width="30%">
            <span v-if="userForm.subscribe" style="margin-right:20px">是否关闭订阅</span>
            <span v-else style="margin-right:20px">是否开启订阅</span>
            <el-radio v-model="subscription" :label="true">是</el-radio>
            <el-radio v-model="subscription" :label="false">否</el-radio>
            <span slot="footer" class="dialog-footer">
              <el-button @click="showSub = false">取 消</el-button>
              <el-button type="primary" @click="editSub">确 定</el-button>
            </span>
          </el-dialog>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
export default {
  mixins: [BaseMixin],
  data() {
    return {
      activeName: "",
      currentResidence: "",
      GraduationDate: "",
      birthday: "",
      resumeList: [],
      checkIndex: "3-1",
      tabIndex: 0,
      afterDates: "",
      packetList: [],
      dialogVisible: false,
      orderId: '',
      userForm: {
        nickName: 'sunshine',
        userPwd: '********',
        gender: '男',
        email: '2222222222@qq.com',
        select: '1'
      },
      showSub: false,
      subscription: true,
    };
  },
  methods: {
    initData() {
      this.userForm = {
        phone: this.vuex_user.phone,
        nickName: this.vuex_user.nickName,
        gender: this.vuex_user.gender,
        email: this.vuex_user.email,
        subscribe: this.vuex_user.subscribe
      }
      let afterDate = new Date().getTime();
      this.afterDates = this.$dy_tool.formatDate(afterDate, "yyyy-MM-dd");
      this.$u.vuex("refreshTimes", this.afterDates)
      this.$u.api.getResume().then((res) => {
        // const info = res.data
        this.resumeList = res.data;
        console.log(res.data);
        res.data.forEach((e) => {
          //出生日期

          e.birthday = this.$dy_tool.formatDate(Number(e.birthday), "yyyy-MM-dd") || e.birthday || "";
          // console.log(e.birthday)

          //籍贯
          // this.$nextTick(function(){
          // 	e.nativePlace = e.nativeProvince + e.nativeCity
          // })
          e.currentResidence = (e.dwellProvince || '') + (e.dwellCity || '');
          if(e.sex == "0"){
            e.sex =  "保密"
          }else if(e.sex == "1"){
            e.sex =  "男"
          }else if(e.sex == "2"){
            e.sex =  "女"
          }
          e.GraduationDate = e.graduationYear ? (e.graduationYear + "-" + e.graduationMonth) : '';
          console.log(e.marriage);
          // if(e.marriage != null) {
          //   if(e.marriage == true) {
          //     e.marriaged = "已婚"
          //   }else if(e.marriage == false) {
          //     e.marriaged = "未婚"
          //   }
          // }
          e.marriage = e.marriage === null ? '' : e.marriage === 'true' ? "已婚" : e.marriage === 'false' ? "未婚" : '';
          console.log(e.marriage);
          e.wantedCertificates.forEach((es) => {
            console.log(es);
            es.endDate = this.$dy_tool.formatDate(es.endDate, "yyyy-MM-dd");
            es.startDate = this.$dy_tool.formatDate(es.startDate, "yyyy-MM-dd");
            if (es.certificate) {
              es.certificateId = es.certificate.id;
            }
            if (es.detail) {
              es.detailId = es.detail.id;
              es.name = es.certificate.name + "-" + es.detail.label;
            }


          });
        });

        this.activeName = this.resumeList[this.tabIndex].id + "";
        console.log(res.data);
      });
    },
    handleClick(tab) {
      this.tabIndex = tab.index;
      console.log(this.resumeList[tab.index].id);
    },
    addResume() {
      if (this.resumeList.length >= 5) {
        this.uni.showToast('简历数量太多，不可再添加');
      } else {
        this.jump("pc_add_resume");
      }
    },
    allRefresh() {
      this.$u.api.resumeOneKeyRefresh().then(res => {
        console.log(res);
        this.uni.showToast('简历一键刷新成功');
      })
    },
    recruit() {
      // this.$u.vuex("recruitmentType", 1);
      this.jump("the_crew_recruitment?id=" + this.resumeList[this.tabIndex].id);
      // this.$u.route({
      //   url: ,
      //   type: "switchTab",
      // });
      console.log("招聘船员");
    },
    amend() {
      this.jump("pc_add_resume?id=" + this.resumeList[this.tabIndex].id);
    },
    refreshResume() {
      // if (this.refreshTimes && this.refreshTimes === this.afterDates) {
      //   this.uni.showToast({
      //     title: "今日次数已用",
      //     icon: "none",
      //   })
      //   return
      // }
      const that = this;
      // that.$u.vuex("refreshTimes", this.afterDates)
      that.$u.api.resumeRefresh({ id: that.resumeList[that.tabIndex].id }).then((res) => {
        this.uni.showToast({
          title: "简历已免费置顶",
          icon: "none",
        })
      })
      // this.uni.showModal({
      //   title: "温馨提示",
      //   content: "是否使用一次免费置顶",
      //   confirmText: "确定",
      //   confirmColor: "#2089FF",
      //   showCancel: true,
      //   success: (res) => {
      //     console.log(res);
      //     if (res.confirm) {
      //       console.log(this.afterDates)
      //       that.$u.api.resumeRefresh({ id: that.resumeList[that.tabIndex].id}).then((res) => {
      //       this.uni.showToast({
      //         title: "简历已免费置顶",
      //         icon: "none",
      //       });
      //       that.times()
      //     });
      //     }
      //   },
      // });
    },
    stick() {
      this.$u.api.resumeGetResume().then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          res.data.forEach((e) => {
            e.isCheck = false
            if (e.unit == 1) {
              this.unit = "年";
            } else if (e.unit == 2) {
              this.unit = "月";
            } else if (e.unit == 3) {
              this.unit = "天";
            } else if (e.unit == 4) {
              this.unit = "时";
            }
          });
          this.packetList = res.data;
          this.dialogVisible = true;
        } else {
          this.uni.showToast('暂无可使用的置顶套餐，请前往购买');
        }

      });
    },
    openResume() {
      // let tabId = this.tabIndex
      // this.tabIndex = tabId
      this.$u.api
        .openChanges({
          id: this.resumeList[this.tabIndex].id,
          isOpen: !this.resumeList[this.tabIndex].isOpen,
        })
        .then((res) => {
          if (this.resumeList[this.tabIndex].isOpen) {
            this.uni.showToast({
              title: "简历已隐藏",
              icon: "none",
            });
            this.initData();
          } else {
            console.log(res);
            this.uni.showToast({
              title: "简历已公开",
              icon: "none",
            });
            this.initData();
          }
          // setTimeout(() => {
          // 	this.$router.go(0);
          // }, 400);
        });
    },
    delResume() {
      this.uni.showModal({
        title: '提示',
        content: '是否要删除简历' + (this.tabIndex + 1) + '？',
        success: (res) => {
          if (res.confirm) {
            this.$u.api.resumeDel({ id: this.resumeList[this.tabIndex].id }).then((res) => {
              this.uni.showToast({
                title: "删除成功!",
                icon: "none",
              });
              this.$u.api.getResume().then((res) => {
                this.resumeList = res.data;
                res.data.forEach((e) => {
                  this.birthday = this.$dy_tool.formatDate(e.birthday, "yyyy-MM-dd");
                  this.currentResidence = e.dwellProvince + e.dwellCity;
                  e.sex = e.sex == "1" ? "男" : "女";
                  this.GraduationDate = e.graduationYear + "-" + e.graduationMonth;
                  e.marriage = e.marriage ? "已婚" : "未婚";
                  e.wantedCertificates.forEach((es) => {
                    es.endDate = this.$dy_tool.formatDate(es.endDate, "yyyy-MM-dd");
                    es.startDate = this.$dy_tool.formatDate(es.startDate, "yyyy-MM-dd");
                    es.certificateId = es.certificate.id;
                    es.detailId = es.detail.id;
                    es.name = es.certificate.name + "-" + es.detail.label;
                  });
                });

                this.activeName = this.resumeList[this.tabIndex].id + "";
              });
            });
          }
        }
      });
    },
    handleClose() { },
    checkPackage(id) {
      this.packetList.forEach(element => {
        element.isCheck = false
      })
      console.log(id);
      console.log(this.packetList[id].isCheck);
      this.packetList[id].isCheck = !this.packetList[id].isCheck
      this.orderId = ''
      if (this.packetList[id].isCheck) {
        this.orderId = this.packetList[id].id
      }
      console.log(this.packetList[id].isCheck);

    },
    buyConfirm() {
      this.$u.api.resumeTop({ id: this.resumeList[this.tabIndex].id, orderId: this.orderId }).then(res => {
        this.uni.showToast({
          title: '简历已置顶',
          icon: 'none'
        })
      })
    },
    editSub() {
      if (this.subscription) {
        this.userForm.subscribe = true
        this.$u.api.updateInfo(this.userForm).then(res => {
          this.$u.api.getInfo().then(resp => {
            this.$u.vuex('vuex_user', resp.data)
            this.uni.showToast('订阅开启成功');
            this.showSub = false
          })
        })
      } else {
        this.userForm.subscribe = false
        this.$u.api.updateInfo(this.userForm).then(res => {
          this.$u.api.getInfo().then(resp => {
            this.$u.vuex('vuex_user', resp.data)
            this.uni.showToast('订阅关闭成功');
            this.showSub = false
          })
        })
      }

    },
    check(index) {
      this.checkIndex = index;
      if (index == "1") {
        this.jump("account_manage");
      } else if (index == "2") {
        this.jump("buy_package");
      } else if (index == "3-2") {
        this.jump("delivery_record");
      } else if (index == "3-3") {
        this.jump("collection_position");
      } else if (index == "4") {
        this.jump("personal_guarantee");
      } else if (index == "5") {
        this.jump("training_order");
      } else if (index == "6") {
        this.jump("document_handling");
      } else if (index == "7") {
        this.showSub = true
      }
      console.log(this.checkIndex);
    },
    ab() {
      console.log("12327");
    },
  },
};
</script>
<style>
page {
  background: none;
}

.el-form>>>.el-input .el-input__inner,
.el-form>>>.el-textarea .el-textarea__inner {
  font-size: 0.833vw;
}

.el-form>>>.el-form-item__label {
  font-size: 0.833vw;
}

.el-tabs>>>.el-tabs__item {
  font-size: 0.9375vw;
  font-weight: 500;
  color: #999999;
}

.el-tabs>>>.el-tabs__item.is-active {
  color: #333333;
}

.el-submenu .el-menu-item {
  min-width: 100%;
}
</style>
<style lang="less" scoped>
.personal {
  // margin: 0 15.25vw 6.25vw;
  margin: 0  auto; width:68.5vw; /* 边距 */
  padding-top: 30px;
  background: #fff;
  box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

  .menuContent {
    background: #fff;
    padding: 0.5vw 2.083vw 1.5625vw;

    .resumeManage {
      position: relative;

      .form_border {
        .el-row {
          border-right: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          // border-top: 1px solid #ccc;
          &:first-child {
            border-top: 1px solid #ccc;
          }

          .el-col {
            padding: 0 20px;
            border-right: 1px solid #ccc;

            &:nth-child(3n) {
              border-right: none;
            }
          }
        }

        .el-form-item {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .noResume {
        // position: absolute;
        padding: 11.979vw;
        text-align: center;

        // left: 360px;
        div {
          font-size: 0.833vw;
        }

        button {
          font-size: 0.833vw;
          margin-top: 3.6458vw;
          width: 6.25vw;
        }
      }

      .addBtn {
        position: absolute;
        right: 12px;
        font-size: 0.729vw;
        background-color: #2089ff;
        // width: 5.208vw;
      }

      .addBtn1 {
        position: absolute;
        right: 100px;
        color: #666;
        background-color: #fff;
        font-size: 0.729vw;
        // width: 5.208vw;
      }

      .items {
        margin-top: 3.125vw;
      }

      .tableContent {
        padding: 2.083vw 0 0 0;

        .borderLeft {
          border-left: 0.208vw solid #2089ff;
          font-size: 1.04vw;
          color: #333;
          font-weight: 500;
          padding: 0 0 0 0.52vw;
          margin: 0;
          line-height: 1.04vw;
        }

        .el-form,
        .el-table,
        .contactInfo {
          margin-top: 2.083vw;
        }
      }

      .tips {
        font-size: 0.728vw;
        color: #999999;
      }

      .contactInfo {
        font-size: 0.833vw;
      }

      .bottomBtn {
        // margin-top: 3.125vw;
        display: flex;
        text-align: center;
        margin-bottom: 1.5vw;

        button {
          width: 6.25vw;
          font-size: 0.833vw;
        }
      }

      .packageUse {

        .takeEffect {
          display: flex;
          flex-wrap: wrap;

          .info {
            margin: 24px24px0 0;
            width: 324rpx;
            background-color: rgba(38, 140, 255, 0.05);
            box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.02);
            border-radius: 8rpx;
            font-size: 1.35vw;
            color: #333;

            .enterprise {
              margin-top: 30rpx;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #999;
              font-size: 1.15vw;
            }
          }

          .check {
            color: #2089ff;
            border: 1px solid #2089ff;
          }
        }
      }

    }
  }

}
</style>
