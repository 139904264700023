<template>
	<div>
		<div class="enterprise">
			<div style="background-color: #fff;">
				<el-row>
					<el-col :span="4">
						<el-menu default-active="3" @select="check" class="el-menu-vertical-demo" @click="check">
							<el-menu-item index="1">
								<span slot="title">首页</span>
							</el-menu-item>
							<el-menu-item index="2">
								<span slot="title">企业账号中心</span>
							</el-menu-item>
							<el-menu-item index="3">
								<span slot="title">套餐购买</span>
							</el-menu-item>
							<el-submenu index="4">
								<template slot="title">
									<span>企业招聘</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="4-1">发布招聘信息</el-menu-item>
									<el-menu-item index="4-2">管理招聘信息</el-menu-item>
									<el-menu-item index="4-3">收到的简历投递</el-menu-item>
									<el-menu-item index="4-4">已收藏简历</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-menu-item index="5">
								<!-- <template> -->
								<span slot="title">企业培训订单</span>
								<!-- </template>
				        <el-menu-item-group>
				          <el-menu-item index="5-1">培训信息</el-menu-item>
				          <el-menu-item index="5-2">培训</el-menu-item> -->
								<!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
								<!-- </el-menu-item-group> -->
							</el-menu-item>
							<!-- <el-menu-item index="6">
				          <span slot="title">企业办证订单</span>
				      </el-menu-item> -->
							<!-- <el-submenu index="6">
							  <template slot="title">
							    <span>企业办证</span>
							  </template>
							  <el-menu-item-group>
							    <el-menu-item index="6-1">办证信息</el-menu-item>
							    <el-menu-item index="6-2">办证订单</el-menu-item>
							  </el-menu-item-group>
							</el-submenu> -->
							<el-menu-item index="7">
								<span slot="title">企业公告</span>
							</el-menu-item>
							<el-menu-item index="8">
								<span slot="title">中介担保</span>
							</el-menu-item>
							<!-- <el-menu-item index="9">
							  <span slot="title">企业资料</span>
							</el-menu-item> -->
							<el-menu-item index="10">
								<span slot="title">我的订阅</span>
							</el-menu-item>
						</el-menu>
					</el-col>
					<el-col :span="20">
						<div class="menuContent">
							<div class="buyPackage">
								<div class="package-title">置顶套餐购买 <span>(购买置顶套餐后才能刷新简历,一小时可刷新一次)</span></div>
								<div v-if="index == 1">
									<div class="takeEffect">
										<div v-if="buyPacketList.length > 0" class="takeEffectTip">套餐生效中</div>

										<div ref="package" style="display: inline-block" class="info" v-for="(item, idx) in buyPacketList"
											:key="idx">
											<div class="content" style="width: 162px; margin-bottom: 0">
												<div class="enterprise1">
													<div>
														<div>名称：{{ item.packet.name }}</div>
														<div style="margin-top:10px">置顶时长（{{ item.packet.unit == 1 ? '年' : item.packet.unit == 2 ? '月'
															: item.packet.unit == 3 ? '天' : '时' }}）：{{ item.packet.timeLong }}</div>
														<div>剩余次数（次）：{{ item.surplus }}</div>
													</div>
												</div>
											</div>
										</div>
										<div class="takeEffectTip" style="margin-top:20px">可购买套餐</div>
										<div :data-id="item.id" ref="package" style="display: inline-block" class="info"
											:class="item.isCheck ? 'check' : 'nocheck'" v-for="(item, idx) in packetList" :key="idx"
											@click="checkPackage(item.id, idx)">
											<div class="content" style="width: 150px; margin-bottom: 0">
												<div class="bold">{{ item.name }}</div>
												<div class="enterprises">
													<div>
														<div>时长（{{ unit }}）：{{ item.timeLong }}</div>
														<div>次数（次）：{{ item.num }}</div>
														<div style="margin-top: 20px">金额：<span style="color: #f95022">￥{{ item.free }}</span></div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="buy-input">

										<div class="inp">
											<div>购买份数（份）:</div>
											<el-input v-model="input" @input="fenshu" type="number" :max="100000" :maxlength="10">
												<span slot="suffix">份</span>
											</el-input>
										</div>
										<div class="inp">
											<div>共计</div>
											<div class="total">￥<span>{{ totalPrice }}</span></div>
										</div>
									</div>
									<div class="goBuy">
										<el-button @click="buyNow" type="primary">立即购买</el-button>
									</div>
								</div>
								<div class="pay_content" v-if="index == 2">
									<div class="pay_header">
										<div>
											{{ packetName }}
											<div class="header_price">¥{{ payPrice }}</div>
										</div>
										<div>请于<span>{{ expires }}分</span>内完成支付</div>
									</div>
									<div class="pay_erweima">
										<VueQr draggable="false" :correctLevel="3" :dotScale="1" :logoSrc="logoSrc" :margin="15" :size="256"
											:text="codeUrl" />
									</div>
									<div class="pay_saoyisao">
										<div>
											<img src="@/static/saoyisao.png" alt="">
										</div>
										<div style="margin-left:20px;font-size:16px;line-height:24px">
											<div>请使用微信“扫一扫”</div>
											<div>扫描二维码支付</div>
										</div>
									</div>
									<div class="pay_price">
										¥{{ payPrice }}
									</div>
								</div>
							</div>
						</div>

						<el-dialog title="提示" :visible.sync="showSub" width="30%">
							<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
							<span v-else style="margin-right:20px">是否开启订阅</span>
							<el-radio v-model="subscription" :label="true">是</el-radio>
							<el-radio v-model="subscription" :label="false">否</el-radio>
							<span slot="footer" class="dialog-footer">
								<el-button @click="showSub = false">取 消</el-button>
								<el-button type="primary" @click="editSub">确 定</el-button>
							</span>
						</el-dialog>
					</el-col>

				</el-row>
			</div>
		</div>

	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			// 二维码logo图片
			logoSrc: require("@/static/payment.png"),
			// 支付二维码地址
			codeUrl: '测试',
			checkIndex: '3',
			index: 1,
			isCheck: false,
			input: 1,
			price: '',
			packageId: 0,
			totalPrice: '0',
			resume: {},
			packetList: [],
			unit: "",
			effectivePlans: [],
			btnStatus: false,
			buyPacketList: [],
			payPrice: 0,
			packetName: '',
			expires: 0,
			showSub: false,
			subscription: true,
			subform: {}
		}
	},
	methods: {
		initData() {
			this.subform = {
				id: this.vuex_user.id,
				nickName: this.vuex_user.nickName,
				avatar: this.vuex_user.avatar,
				gender: this.vuex_user.gender,
				openId: this.vuex_user.openId,
				wxOpenid: this.vuex_user.wxOpenid,
				phone: this.vuex_user.phone,
				password: this.vuex_user.password,
				name: this.vuex_user.name,
				simpleName: this.vuex_user.simpleName,
				province: this.vuex_user.province,
				city: this.vuex_user.city,
				district: this.vuex_user.district,
				address: this.vuex_user.address,
				postalCode: this.vuex_user.postalCode,
				website: this.vuex_user.website,
				intro: this.vuex_user.intro,
				subscribe: this.vuex_user.subscribe,
				isPass: this.vuex_user.isPass,
				openAgency: this.vuex_user.openAgency,
				createTime: this.vuex_user.createTime,
				autid: this.vuex_user.autid
			}
			this.subscription = this.subform.subscribe
			this.$u.api.getRecruitPacket().then((res) => {
				res.data.forEach((e) => {
					if (e.unit == 1) {
						this.unit = "年";
					} else if (e.unit == 2) {
						this.unit = "月";
					} else if (e.unit == 3) {
						this.unit = "天";
					} else if (e.unit == 4) {
						this.unit = "时";
					}
					e.isCheck = false
				});
				res.data[0].isCheck = true
				this.packageId = res.data[0].id
				this.price = res.data[0].free
				this.totalPrice = res.data[0].free
				this.packetList = res.data;

			});
			//获取可使用的招聘套餐
			this.$u.api.getRecruit().then(res => {
				this.buyPacketList = res.data

			})
		},
		checkPackage(id, index) {
			this.packetList.forEach(e1 => {
				e1.isCheck = false
			})
			this.packetList[index].isCheck = !this.packetList[index].isCheck;

			if (this.packetList[index].isCheck) {
				this.packetList.forEach((e) => {
					if (e.id === id) {
						this.price = e.free;
						this.packageId = e.id;
						// this.totalPrice = this.price * this.input;
						if (this.input == 0) {
							this.totalPrice = this.price
						} else {
							this.totalPrice = this.price * this.input;
						}
					}
				});
			} else {
				this.totalPrice = 0
			}

		},
		fenshu() {
			if(this.input >100000){
				this.input = 100000;
			}
			if (this.input == 0) {
				this.totalPrice = this.price;
			} else {
				this.totalPrice = this.price * this.input;
			}
		},
		buyNow() {

			this.$u.api.companyLoginInfo().then(res => {
				if (res.data.isPass === true && res.data.autid != 2) {
					this.uni.showToast({
						title: '您的企业审核暂未通过',
						icon: 'none'
					});
					return
				}
				if (this.btnStatus == true) {
					return
				}
				if (this.input == 0) {
					this.uni.showToast({
						title: "请填写购买次数",
						icon: "none",
					});
				}
				this.btnStatus = true

				this.$u.api.recruitOrderCreate(this.packageId + "").then((res) => {
					this.uni.showToast({ title: '创建订单成功，请立即支付', icon: 'none' })
					this.payPrice = res.data.orderInfo.free
					this.packetName = res.data.orderInfo.packet.name
					this.expires = res.data.expires
					this.$u.api.orderPay({ from: 'pc', orderType: 'recruit', uni: res.data.orderInfo.id }).then(data => {
						this.codeUrl = data.data.wxPayResult
						this.index = 2
					}).catch(() => {
						// this.buttonStatus = false
					})
					this.btnStatus = false
				}).catch(() => {
					this.btnStatus = false
				})
				// this.$forceUpdate()
			})

		},
		editSub() {
			if (this.subscription) {
				this.$u.api.updateSubscribe(this.subscription + '').then(res => {
					this.uni.showToast('订阅开启成功')
					this.subform.subscribe = true
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false

				})
			} else {
				this.$u.api.updateSubscribe(this.subscription + '').then(res => {
					this.uni.showToast('订阅关闭成功')
					this.subform.subscribe = false
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
				})
			}

		},
		check(index) {
			this.checkIndex = index
			if (index == '1') {
				this.jump('enterprise_index')
			} else if (index == '2') {
				this.jump('ec_account_manage')
			}
			else if (index == '4-1') {

				if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
					this.uni.showToast({
						title: '您的企业审核暂未通过',
						icon: 'none'
					});
					return
				}
				this.jump('post_recruitment_info')

			}
			else if (index == '4-2') {
				this.jump('manage_recruitment_info')
			}
			else if (index == '4-3') {
				this.jump('resume_delivery_received')
			}
			else if (index == '4-4') {
				this.jump('collected_resume')
			}
			else if (index == '5-1') {
				this.jump('ec_training_info')
			}
			else if (index == '5') {
				this.jump('ec_training_order')
			}
			else if (index == '5-3') {
				this.jump('training_school')
			}
			else if (index == '7') {

				if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
					this.uni.showToast({
						title: '您的企业审核暂未通过',
						icon: 'none'
					});
					return
				}
				this.jump('corporate_announcements')

			}
			else if (index == '8') {
				// this.$u.api.companyLoginInfo().then(res => {
				// 	if(res.data.openAgency) {
				if (this.vuex_user.openAgency) {
					this.jump('ec_mediation_guarantee')
				} else {
					this.uni.showToast('您未开通中介担保')
				}
				// })
			}
			else if (index == '9') {
				this.jump('published_data')
			}
			else if (index == '10') {
				this.showSub = true
			}
		},
	}
}
</script>
<style>
page {
	background: none;
}

.el-submenu .el-menu-item {
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.enterprise {
	margin: 0  auto; width:68.5vw; /* 边距 */
	padding-top: 30px;
	background: #fff;
	box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

	.menuContent {
		background: #fff;
		padding: 0.5vw 2.083vw 1.5625vw;

		.buyPackage {
			.package-title {
				font-size: 1.04167vw;
				font-weight: 500;

				span {
					color: #999999;
					font-size: 0.833vw;
					margin-left: 0.5729vw;
				}
			}

			.takeEffect {
				margin-top: 50px;
				padding: 20px;
				background-color: rgba(38, 140, 255, 0.05);

				.takeEffectTip {
					color: #2089FF;
					font-size: 16px;
				}

				.takeEffectContent {
					margin-top: 20px;
					display: flex;

					.contentItem {
						font-size: 16px;
						margin-right: 50px;
						color: #999999;

						span {
							color: #333333;
						}
					}
				}
			}

			.buy-input {
				margin-top: 3.4375vw;
				overflow: hidden;

				.inp {
					display: flex;
					align-items: center;
					margin-bottom: 1.5625vw;

					div {
						color: #666666;
						font-size: 0.833vw;

					}

					.el-input {
						width: 8.33vw;
						height: 2.083vw;
						line-height: 2.083vw;
						margin-left: 1.5625vw;
					}

					.total {
						color: #F95022;
						margin-left: 1.5625vw;
						font-size: 0.833vw;

						span {
							font-size: 1.354vw;
						}
					}
				}
			}

			.goBuy {
				margin-top: 1.04167vw;

				.el-button {
					max-width: 120px;
					// height: 2.3958vw;
				}
			}

			.pay_content {
				margin-top: 30px;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				.pay_header {
					text-align: left;
					width: 100%;
					font-size: 16px;
					background-color: rgba(38, 140, 255, 0.05);
					padding: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.header_price {
						margin-top: 10px;
						color: #F95022;
						font-size: 24px;
					}

					span {
						color: #2089FF;
					}
				}

				.pay_erweima {
					margin-top: 30px;

					img {
						width: 200px;
						height: 200px;
						vertical-align: top;
					}
				}

				.pay_saoyisao {
					text-align: left;
					border: 1px solid #2089FF;
					border-radius: 4px;
					padding: 43px 84px;
					display: flex;
					align-items: center;
					width: 400px;
					box-sizing: border-box;
					margin: 2.08vw auto 50px;

					img {
						width: 50px;
						height: 50px;
						display: block;
					}
				}

				.pay_price {
					color: #F95022;
					font-size: 42px;
				}
			}
		}
	}

	.info {
		margin: 24px24px0;
		padding: 25rpx;
		background: #ffffff;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.02);
		border-radius: 8rpx;
		font-size: 1.35vw;
		color: #333;

		.enterprises {
			margin-top: 30rpx;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #999;
			font-size: 1.15vw;
		}

		.enterprise1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 30rpx;
		}
	}

	.check {
		color: #2089ff;
		border: 1px solid #2089ff;
	}

	.nocheck {}
}
</style>

