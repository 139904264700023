<template>
	<div>
		<div class="enterprise">
			<div style="background-color: #fff;">
				<el-row>
					<el-col :span="4">
						<el-menu default-active="7" @select="check" class="el-menu-vertical-demo" @click="check">
							<el-menu-item index="1">
								<span slot="title">首页</span>
							</el-menu-item>
							<el-menu-item index="2">
								<span slot="title">企业账号中心</span>
							</el-menu-item>
							<el-menu-item index="3">
								<span slot="title">套餐购买</span>
							</el-menu-item>
							<el-submenu index="4">
								<template slot="title">
									<span>企业招聘</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="4-1">发布招聘信息</el-menu-item>
									<el-menu-item index="4-2">管理招聘信息</el-menu-item>
									<el-menu-item index="4-3">收到的简历投递</el-menu-item>
									<el-menu-item index="4-4">已收藏简历</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-menu-item index="5">
								<!-- <template> -->
								<span slot="title">企业培训订单</span>
								<!-- </template>
				        <el-menu-item-group>
				          <el-menu-item index="5-1">培训信息</el-menu-item>
				          <el-menu-item index="5-2">培训</el-menu-item> -->
								<!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
								<!-- </el-menu-item-group> -->
							</el-menu-item>
							<!-- <el-menu-item index="6">
				          <span slot="title">企业办证订单</span>
				      </el-menu-item> -->
							<!-- <el-submenu index="6">
							  <template slot="title">
							    <span>企业办证</span>
							  </template>
							  <el-menu-item-group>
							    <el-menu-item index="6-1">办证信息</el-menu-item>
							    <el-menu-item index="6-2">办证订单</el-menu-item>
							  </el-menu-item-group>
							</el-submenu> -->
							<el-menu-item index="7">
								<span slot="title">企业公告</span>
							</el-menu-item>
							<el-menu-item index="8">
								<span slot="title">中介担保</span>
							</el-menu-item>
							<!-- <el-menu-item index="9">
							  <span slot="title">企业资料</span>
							</el-menu-item> -->
							<el-menu-item index="10">
								<span slot="title">我的订阅</span>
							</el-menu-item>
						</el-menu>
					</el-col>
					<el-col :span="20">
						<div class="menuContent">

							<div v-if="tableData.length == 0" class="noRegistration">
								<div>您的企业还没有企业公告</div>
								<el-button type="primary" @click="jump('add_announcements')">发布公告</el-button>
							</div>
							<div v-else>
								<div class="trainInfo">
									<div class="trainInfo-title">企业公告</div>
									<el-button type="default" @click="jump('add_announcements')">发布公告</el-button>
								</div>
								<el-form ref="form" :model="formInline" label-width="100px">
									<el-form-item label="发布时间：">
										<!-- <el-button type="text" @click="checkStatus">不限</el-button> -->
										<el-col :span="11">
											<el-date-picker v-model="formInline.createTime" type="datetimerange" align="right" unlink-panels
												clearable value-format="yyyy-MM-dd hh:mm:ss" range-separator="-" start-placeholder="开始日期"
												end-placeholder="结束日期" @change="dateChange"></el-date-picker>
										</el-col>
									</el-form-item>
									<el-form-item label="公告状态：">
										<el-button type="text" @click="checkStatus('')">不限</el-button>
										<!-- <el-button type="text" @click="checkStatus()">已发布</el-button> -->
										<el-button type="text" @click="checkStatus('isShowf')">已隐藏</el-button>
										<el-button type="text" @click="checkStatus('isShowt')">已显示</el-button>
									</el-form-item>
									<el-form-item label="公告类型：">
										<el-select v-model="formInline.categoryName" @change="typeChange" clearable placeholder="请选择">
											<el-option v-for="item in types" :key="item.name" :label="item.label" :value="item.name">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="公告搜索：" class="enterprise-search">
										<el-input placeholder="输入公告标题" v-model="formInline.title" @input="clearInput"
											class="input-with-select">

										</el-input>
										<el-button @click="initData(formInline)" ref="searchBtn"
											:type="formInline.title ? 'primary' : 'info'">搜索</el-button>
									</el-form-item>
								</el-form>
								<el-table border :data="tableData" style="width: 100%" :header-cell-style="{
									'background-color': 'rgba(32, 137, 255, 0.07)',
									color: '#333',
									'font-size': '0.833vw',
									'font-weight': '400'
								}">
									<!-- <el-table-column
		     type="selection"
		     width="55">
		   </el-table-column> -->
									<el-table-column prop="category.label" label="公告类型"></el-table-column>
									<el-table-column prop="title" label="公告标题"></el-table-column>
									<el-table-column prop="status" label="显示状态" width="90">
										<template slot-scope="scope">
											<div>{{ scope.row.isShow ? '已显示' : '已隐藏' }}</div>
										</template>
									</el-table-column>
									<!-- <el-table-column prop="status" label="发布状态" width="90">
				<template slot-scope="scope">
					<div>{{scope.row.isSend ? '已发布' : '未发布'}}</div>
				</template>
			</el-table-column> -->
									<el-table-column prop="createTime" label="发布时间">
										<template slot-scope="scope">
											{{ $dy_tool.formatDate(scope.row.createTime, 'yyyy-MM-dd') }}
										</template>
									</el-table-column>
									<el-table-column label="操作" width="140">
										<template slot-scope="scope">
											<el-button type="text" @click="jump('notice_detail?id=' + scope.row.id)">详情</el-button>
											<el-button type="text" @click="jump('add_announcements?id=' + scope.row.id)">编辑</el-button>
											<!-- <el-button type="text" @click="handleHide(scope.$index, scope.row)">{{scope.row.isShow ? '隐藏' : '显示'}}</el-button> -->
											<!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)">{{scope.row.isSend ? '取消发布' : '发布'}}</el-button> -->
											<!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)">删除</el-button> -->
										</template>
									</el-table-column>
								</el-table>
								<!-- <div class="tableBottom"> -->
								<!-- <div>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量发布</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量隐藏</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量删除</el-button>
			</div> -->
								<div style="text-align:right;"><el-pagination style="margin-top:20px;text-align:right;"
										:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="10"
										layout="prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
										@current-change="handleCurrentChange" /></div>
								<!-- </div> -->
							</div>
						</div>
					</el-col>

				</el-row>
			</div>
		</div>

	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			checkIndex: '7',
			form: {
				date: '',
				employJob: '选项1',
				search: ''
			},
			options: [
				{
					value: '选项1',
					label: '全部类型'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [],
			select: '1',
			currentPage: 1,
			total: 1,
			formInline: {
				categoryName: "", // 搜索字段
				orderNum: "",
				status: 0,
				page: 0, // 当前页
				size: 10,
			},
			types: []
		}
	},
	methods: {
		initData(formInline) {
			this.$u.api.companyArticle(formInline).then(res => {
				this.total = res.data.totalElements
				this.tableData = res.data.content

			})
			this.$u.api.companyArticleCategory().then((res) => {

				this.types = res.data;
				// this.selectItem(res.data, this.types);
			});
		},
		onSubmit() {
			console.log('submit!');
		},
		handleHide(index, row) {
			if (row.isShow) {
				this.$u.api.showChanges({ id: row.id, isShow: !row.isShow }).then(res => {
					this.uni.showToast('公告隐藏成功')
					this.initData()
				})
			} else {
				this.$u.api.showChanges({ id: row.id, isShow: !row.isShow }).then(res => {
					this.uni.showToast('公告显示成功')
					this.initData()
				})
			}

		},
		handleEdit(index, row) {
		},
		checkStatus(e) {
			if (e == '') {
				this.formInline.isShow = ''
				this.initData(this.formInline)
			} else if (e == 'isShowf') {
				this.formInline.isShow = false
				this.initData(this.formInline)
			} else if (e == 'isShowt') {
				this.formInline.isShow = true
				this.initData(this.formInline)
			}

		},
		clearInput(e) {
			if (e == '') {
				this.formInline.title = ''
				this.initData(this.formInline)
			}
			// 
		},
		dateChange(e) {

			this.initData(this.formInline)
		},
		typeChange(e) {
			// 
			this.initData(this.formInline)
			// console.log(this.formInline);
		},
		handleSizeChange(val) {
			this.formInline.size = val
			this.initData(this.formInline)
		},
		handleCurrentChange(val) {
			this.formInline.page = val - 1
			this.initData(this.formInline)
		},
		check(index) {
			this.checkIndex = index
			if (index == '1') {
				this.jump('enterprise_index')
			} else if (index == '2') {
				this.jump('ec_account_manage')
			}
			else if (index == '3') {
				this.jump('ec_buy_package')
			}
			else if (index == '4-1') {
				this.jump('post_recruitment_info')
			}
			else if (index == '4-2') {
				this.jump('manage_recruitment_info')
			}
			else if (index == '4-3') {
				this.jump('resume_delivery_received')
			}
			else if (index == '4-4') {
				this.jump('collected_resume')
			}
			else if (index == '5-1') {
				this.jump('ec_training_info')
			}
			else if (index == '5') {
				this.jump('ec_training_order')
			}
			else if (index == '5-3') {
				this.jump('training_school')
			}
			else if (index == '8') {
				this.jump('ec_mediation_guarantee')
			}
			else if (index == '10') {
				this.jump('my_subscription')
			}
		},
	}
}
</script>
<style>
page {
	background: none;
}

.enterprise-search>>>.el-form-item__content {
	display: flex;
}
</style>
<style lang="less" scoped>
.enterprise {
	margin: 0  auto; width:68.5vw; /* 边距 */
	padding-top: 30px;
	background: #fff;

	.menuContent {
		background: #fff;
		padding: 0.5vw 2.083vw 1.5625vw;
	}
}

.noRegistration {
	// position: absolute;
	padding: 11.979vw;
	text-align: center;

	// left: 360px;
	div {
		font-size: 0.833vw;
	}

	button {
		font-size: 0.833vw;
		margin-top: 3.6458vw;
		width: 6.25vw;
	}
}

.trainInfo {
	// width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 20px;

	.trainInfo-title {
		font-size: 1.0416vw;
	}

	button {
		margin: 0;
		width: 100px;
		background-color: #2089ff;
		font-size: 14px;
		color: #ffffff;
	}
}

.el-form {
	margin-top: 2.604vw;

	.el-input {
		width: 15.625vw;
		margin-right: 0.52vw;
	}
}

.el-table {
	margin-top: 2.083vw;
}

.tableBottom {
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// padding: 0.833vw 0 6.25vw;
}
</style>
