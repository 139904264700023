<template>
	<div class="enterprise">
		<div style="background-color: #fff;">
		<el-row>
			<el-col :span="4">
				<el-menu
				      default-active="10"
							@select="check"
				      class="el-menu-vertical-demo"
							@click="check">
				      <el-menu-item index="1">
				        <span slot="title">首页</span>
								</el-menu-item>
				      <el-menu-item index="2">
				        <span slot="title">企业账号中心</span>
				      </el-menu-item>
				      <el-menu-item index="3">
				        <span slot="title">套餐购买</span>
				      </el-menu-item>
				      <el-submenu index="4">
				        <template slot="title">
				          <span>企业招聘</span>
				        </template>
				        <el-menu-item-group>
				          <el-menu-item index="4-1">发布招聘信息</el-menu-item>
				          <el-menu-item index="4-2">管理招聘信息</el-menu-item>
				          <el-menu-item index="4-3">收到的简历投递</el-menu-item>
				          <el-menu-item index="4-4">已收藏简历</el-menu-item>
				        </el-menu-item-group>
				      </el-submenu>
				      <!-- <el-menu-item index="5"> -->
				        <!-- <template> -->
				          <!-- <span slot="title">企业培训订单</span> -->
				        <!-- </template>
				        <el-menu-item-group>
				          <el-menu-item index="5-1">培训信息</el-menu-item>
				          <el-menu-item index="5-2">培训</el-menu-item> -->
				          <!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
				        <!-- </el-menu-item-group> -->
				      <!-- </el-menu-item> -->
							<!-- <el-menu-item index="6">
				          <span slot="title">企业办证订单</span>
				      </el-menu-item> -->
							<!-- <el-submenu index="6">
							  <template slot="title">
							    <span>企业办证</span>
							  </template>
							  <el-menu-item-group>
							    <el-menu-item index="6-1">办证信息</el-menu-item>
							    <el-menu-item index="6-2">办证订单</el-menu-item>
							  </el-menu-item-group>
							</el-submenu> -->
				      <el-menu-item index="7">
				        <span slot="title">企业公告</span>
				      </el-menu-item>
				      <el-menu-item index="8">
				        <span slot="title">中介担保</span>
				      </el-menu-item>
							<!-- <el-menu-item index="9">
							  <span slot="title">企业资料</span>
							</el-menu-item> -->
							<el-menu-item index="10">
							  <span slot="title">我的订阅</span>
							</el-menu-item>
				    </el-menu>
			</el-col>
			<el-col :span="20">
				<div class="menuContent">
		<div style="font-size: 1.0416vw;">我的订阅</div>
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="收到时间：">
					<!-- <el-button type="text" @click="checkStatus">不限</el-button> -->
					<el-col :span="11">
						<el-date-picker
						      v-model="form.date"
						      type="date"
						      placeholder="选择日期">
						    </el-date-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="招聘职务：">
					<el-select v-model="form.date" placeholder="请选择">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
				</el-form-item>
			</el-form>
		    <el-table
				border
		    :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
		    style="width: 100%;margin-top: 50px;"
		    :header-cell-style="{'background-color': 'rgba(32, 137, 255, 0.07)','color': '#333','font-weight': '400'}"
		  >
		    <el-table-column prop="name" label="姓名">
		    </el-table-column>
		    <el-table-column prop="job" label="应聘职务">
		    </el-table-column>
		    <el-table-column prop="age" label="年龄">
		    </el-table-column>
		    <el-table-column prop="seaAge" label="海龄">
		    </el-table-column>
		    <el-table-column prop="type" label="证书类型">
		    </el-table-column>
		    <el-table-column prop="zone" label="航区">
		    </el-table-column>
		    <el-table-column prop="time" label="投递日期">
		    </el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
					</template>
				</el-table-column>
		  </el-table>
			<div class="tableBottom">
				
				<div style="text-align:right;">
					<el-pagination
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
		  :current-page.sync="currentPage"
		  :page-size="pagesize"
		  layout="prev, pager, next, jumper"
		  :total="tableData.length">
		</el-pagination>
				</div>
		  
		  </div>
	</div>
			</el-col>
						
		</el-row>
		</div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
  mixins: [BaseMixin],
		data() {
			return {
				checkIndex: '10',
				form: {
					date: '',
					employJob: '选项1',
					search: ''
				},
				options: [
					{
						value: '选项1',
						label: '所有职务'
					},
					{
						value: '选项2',
						label: '机工'
					}
				],
				tableData: [{
				    id: 1,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 2,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 3,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 4,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  },{
				    id: 5,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 6,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 7,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 8,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  },{
				    id: 9,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 10,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 11,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }, {
				    id: 12,
				    name: '刘机工',
				    job: '机工',
				    age: '42',
				    seaAge: '10',
				    type: '丙一',
				    zone: '国内南北线',
				    time: '2021-06-20',
				  }],
				currentPage: 1,
				pagesize: 8,
			};
		},
		methods: {
		  initData() {
		  },
		onSubmit() {
			console.log('submit!');
		},

		handleEdit(index, row) {
		},
		checkStatus(e) {
		},
		check(index) {
						this.checkIndex = index
						if(index == '1') {
							this.jump('enterprise_index')
						}else if(index == '2') {
							this.jump('ec_account_manage')
						}
						else if(index == '3') {
							this.jump('ec_buy_package')
						}
						else if(index == '4-1') {
							this.jump('post_recruitment_info')
						}
						else if(index == '4-2') {
							this.jump('manage_recruitment_info')
						}
						else if(index == '4-3') {
							this.jump('resume_delivery_received')
						}
						else if(index == '4-4') {
							this.jump('collected_resume')
						}
						else if(index == '5-1') {
							this.jump('ec_training_info')
						}
						else if(index == '5') {
							this.jump('ec_training_order')
						}
						else if(index == '5-3') {
							this.jump('training_school')
						}
						else if(index == '7') {
							this.jump('corporate_announcements')
						}
						else if(index == '8') {
							if(this.vuex_user.openAgency) {
								this.jump('ec_mediation_guarantee')
							}else {
								this.uni.showToast({title:'您未开通中介担保',icon:'none'})
							}
							
						}
						else if(index == '9') {
							this.jump('published_data')
						}
						else if(index == '10') {
							
							this.jump('ec_my_subscription')
						}
					},
		}
	}
</script>

<style>
page{
  background:none;
}
.enterprise-search >>> .el-form-item__content {
	display: flex;
}
</style>
<style lang="less" scoped>
	.enterprise {
		margin: 0  auto; width:68.5vw; /* 边距 */
		padding-top: 30px;
		background: #fff;
		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}
	}
	.el-form {
		margin-top: 2.604vw;
		.el-input {
			width: 15.625vw;
			margin-right: 0.52vw;
		}
	}
	.el-table {
		margin-top: 2.083vw;
	}
	.tableBottom {
		
		padding: 0.833vw 0 6.25vw;
	}
</style>
